<script setup>
const backend = useBackend();
const props = defineProps({
  content: {
    type: String,
    required: true,
    default: () => ''
  },
  readAlsoArr: {
    type: Array,
    default: () => []
  },
  seeAlsoArticles: {
    type: Array,
    default: () => []
  },
  category: {
    type: String,
    default: () => ''
  },
  collectionName: {
    type: String,
    default: () => ''
  }
});
const { data: collection } = await useAsyncData(async () => {

const {data} = await backend.getCollection(props.collectionName);
return data;
});
</script>
<template>
  <div>
    <div v-for="(item, index) in content" :key="index">
      <!-- 1st  ContentPart -->
      <template v-if="index === 0">
        <BlogArticleContent :content="item" />
        <BlogReadAlso v-if="readAlsoArr[0]" :readAlsoObj="readAlsoArr[0]" />
      </template>
      <template v-else-if="index > 0 && index < content.length">
        <BlogArticleContent :content="item" />
          <!-- 3th ContentPart -->
          <template v-if="index === 2 && content.length >= 3">
            <BlogSeeAlso v-if="category === 'zdjecia'" :seeAlsoArticles="seeAlsoArticles" />
            <CarCollection
              v-else
              :is-category-page="true"
              :is-moto="true"
              :title="collection?.title"
              :search="collection?.search"
              :href="collection?.slug"
              limit="12"
          />
          </template>
        <!-- Drugi ReadAlso pojawia się po czwartym contentParts  tylko jak jest ich 5+-->
        <template v-if="index === 3 && content.length >= 5">
          <BlogReadAlso  v-if="readAlsoArr[1] && category !== 'zdjecia'" :readAlsoObj="readAlsoArr[1]" />
        </template>
      </template>
      <div
          class="w-full mb-12"
        >
        </div>
    </div>
  </div>
</template>