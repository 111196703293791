<script setup>
    const props = defineProps({
        noAsideCards: Boolean,
        seeAlsoArticles: {
            type: Array,
            default: () => []
        }
    })
    const refSlideControl = ref();
    const refSlideNavigation = ref();
    const updateSlideNumber = (slideNumber) => {
    refSlideNavigation.value.setSlide(slideNumber);
    }

    const scrollToSlide = (slide) => {
    refSlideControl.value.scrollToSlide(slide);
    }
</script>

<template>
    <div class="mt-12 mb-12 border-t border-t-celadon border-b border-b-celadon pb-6">
        <h3 class="text-celadon text-2xl mb-6 pt-6 ">Zobacz także</h3>
        <SlideControl ref="refSlideControl"
                    class="-mt-4"
                    @swipeEnd="(slideNumber) => updateSlideNumber(slideNumber)"
      >
        <template v-for="(item, index) in seeAlsoArticles"  :key="index">
            <SlideItem>
                <BlogAsideCard :key="index" :item="item" />
            </SlideItem>
        </template>
      </SlideControl>
      <SlideNavigation ref="refSlideNavigation"
                       class="-mt-4"
                       :items=8
                       @changed="(slide) => scrollToSlide(slide)"
      />
    </div>
</template>
