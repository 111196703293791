<script setup>
definePageMeta({
  layout: 'moto-layout',
});
const backend = useBackend();
const backendStore = useBackendStore();
const carCount = backendStore.carCount.value;
const route = useRoute();

const slug = ref('');
slug.value = route.path.split('/').pop();

const articleData = ref(null);
const categories = ref([]);
const worthToRead = ref([]);
const chosenArticles = ref([]);
const seeAlsoArticles = ref([]);
const readAlso = ref([]);
const collectionName = ref('');

const { data: articleResponse, pending: articlePending, error: articleError } = await useAsyncData(
  `article-${slug.value}`,
  async () => {
    return backend.getMotoArticleData(slug.value);
  },
  {
    watch: [slug],
  }
);

watch(
  () => articleResponse.value,
  (newVal) => {
    if (articleResponse.value.status === 500) {
      return navigateTo({name: 'moto'})
    }
    if (newVal && newVal.data) {
      articleData.value = newVal.data.article;
      categories.value = newVal.data.categories || [];
      worthToRead.value = newVal.data.worth_to_read || [];
      chosenArticles.value = newVal.data.chosen_articles || [];
      seeAlsoArticles.value = newVal.data.see_also || [];
      readAlso.value = newVal.data.read_also || [];
      collectionName.value = newVal.data.collection || '';
    } else {
      articleData.value = null;
      categories.value = [];
      worthToRead.value = [];
      chosenArticles.value = [];
      seeAlsoArticles.value = [];
      readAlso.value = [];
      collectionName.value = '';
    }
  },
  {immediate: true}
);

const loaded = computed(() => !articlePending.value && articleData.value);

useHead(() => {
  const defaultTitle = 'Moto Vehis - najlepszy portal motoryzacyjny w Polsce';
  const defaultDescription = 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź';

  const seoTitle = articleData.value?.title || defaultTitle;
  const seoDescription = articleData.value?.seo_description || defaultDescription;
  const canonicalUrl = `https://vehis.pl${route.fullPath}`;


  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": seoTitle,
    "author": {
      "@type": "Person",
      "name": articleData.value?.author,
    },
    "datePublished": articleData.value?.publication_date,
    "dateModified": articleData.value?.update_date,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": canonicalUrl
    }
  }

  return {
    title: seoTitle,
    meta: [
      {name: 'description', content: articleData.value?.meta_description || seoDescription},
      {property: 'og:title', content: seoTitle},
      {property: 'og:description', content: articleData.value?.meta_description || seoDescription},
      {property: 'og:type', content: 'article'},
      {property: 'og:image', content: articleData.value?.main_photo || 'https://vehis.pl/moto/assets/img/moto-vehis-logo-new.png'},
    ],
    link: [
      {rel: 'canonical', href: canonicalUrl},
    ],
    script: [
      {
        type: 'application/ld+json',
        children: JSON.stringify(jsonLd)
      }
    ]
  };
});

</script>
<template>
  <Container no-paddings>
    <div v-if="loaded">
      <LayoutColumns>
        <LayoutMainColumn noflex>
          <BlogBreadCrumbs
            :breadcrumbs="[
              { label: 'Strona główna', to: '/moto' },
              { label: articleData.category.name || '', to: `/moto/kategoria/${articleData.category.slug}` },
              { label: articleData.title || '', to: null }
            ]"
          />
          <article>
            <h1 class="pt-6 mb-6 text-3xl font-medium text-azure-dark">{{ articleData.title }}</h1>
            <div class="inline-block mb-6">
              <time class="text-sm font-extralight block mb-1 whitespace-nowrap">
                {{ articleData?.publication_date }}
              </time>
              <p class="border-t border-solid border-black  whitespace-nowrap">
                <router-link :to="`/moto/autor/${articleData?.author}`">  
                  <span class="uppercase mr-1">{{ articleData?.author }}</span>
                </router-link>
                <span class="font-extralight">Autor</span>
              </p>
            </div>
            <p v-if="articleData?.lead_description" class="text-lg mb-12 pr-4">{{ articleData?.lead_description }}</p>
            <BlogPostImage v-if="articleData.category.name !== 'Filmy'" class="mb-12" :featuredImageTitle="articleData?.main_photo_alt" :featuredImage="articleData?.main_photo" />
            <BlogToC v-if="articleData?.toc.length && articleData?.toc.length > 2 && (articleData.category.name !== 'Filmy' && articleData.category.name !== 'Zdjęcia')" :toc="articleData?.toc"/>
            <BlogPostConainer
              v-if="Array.isArray(articleData?.content)"
              :category="articleData.category.slug"
              :readAlsoArr="readAlso"
              :content="articleData?.content"
              :seeAlsoArticles="seeAlsoArticles"
              :collectionName="collectionName"
            />
            <BlogArticleContent v-else :content="articleData?.content" />
            <div class="lg:hidden block">
              <BlogCarOfferBanner v-if="articleData?.foreign_car" :foreignCar="articleData?.foreign_car" />
            </div>
          </article> 
        <FormContact class="mt-12 mb-16" :title="`Szukasz samochodu? Doradca VEHIS ma dla Ciebie oferty ponad ${carCount} dostępnych samochodów!`" standalone />
        </LayoutMainColumn>
        <LayoutSideColumn class="hidden lg:block pb-4 !bg-transparent">
          <MainContentHeader>Warto przeczytać</MainContentHeader>
          <div class="flex gap-6 flex-col mb-6">
            <BlogAsideCard
              v-for="(item, index) in worthToRead"
              :key="index"
              :item="item"
              :position="'Warto przeczytać'"
            />
          </div>
          <BannerSidebar :bannerSlot="'moto-artykul-300x600'" class="mb-6" />
          <BlogCarOfferBanner v-if="articleData?.foreign_car" :foreignCar="articleData?.foreign_car" />
        </LayoutSideColumn>
      </LayoutColumns>
      <BlogChosenArticles v-if="chosenArticles.length > 0" :chosenArticles="chosenArticles" />
    </div>
  </Container>
</template>

<style>
html {
  scroll-behavior: smooth;
}

h2 {
  scroll-margin-top: 150px;
}
</style>
