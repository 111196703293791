<script setup>
const analytics = useAnalytics();
const props = defineProps({
    foreignCar: {
        type: Object,
        default: null
    }
});
</script>
<template>
    <NuxtLink
        :to="`/samochody/${foreignCar?.brand}/${foreignCar?.model}`"
        @click="analytics.analyticsLayer({
            'event': 'select_item',
            'brand': foreignCar.brand,
            'model': foreignCar.model,
            'type': 'single_right_box',
            'link_url': `/samochody/${foreignCar?.brand}/${foreignCar?.model}`,
        })"
        class="w-full block bg-gradient-to-t from-30% from-white via-gray-200 via-40% to-white to-100%
              my-6 rounded-lg border border-gray-300
              shadow-box hover:shadow-box-hover transition-all duration-300 hover:-translate-y-1">
        <div class="absolute z-30">
            <VectorVehisHorizontal class="pl-4 h-5 w-32 mt-4" />
            <p class="pl-4 font-extralight text-base capitalize">Podoba Ci się
                <span class="font-normal">
                    {{ foreignCar?.brand }}
                    {{ foreignCar?.model }}?
                </span>
            </p>
        </div>
        <div
            class="rounded-xl relative h-[187px] select-none pointer-events-none overflow-hidden bg-gradient-to-t from-30% from-white via-gray-200 via-50% to-white to-100%  flex flex-col justify-center items-center">
            <img :src="foreignCar?.photo" onerror="this.setAttribute('data-error', 1)" :alt="`Leasing ${foreignCar?.brand} ${foreignCar?.model}`"
                loading="lazy"
                :srcset="foreignCar?.photo"
                class="z-10 mt-16 h-[140px]">
            <div
                class="bg-gradient-to-t from-gray-100 via-gray-200 to-gray-400 absolute left-0 bottom-0 right-0 h-[80px] z-0">
            </div>
        </div>
        <div class="relative justify-center  px-4 py-2.5 pb-2 font-extralight  overflow-hidden bg-white">
            Sprawdź leasing VEHIS <br />
            dostępny <span class="font-normal">100% online</span>
            <div class="border-t border-t-gray-400 mt-2 pt-2"></div>
            <div>
            <div class="flex justify-between items-baseline text-[10px] font-extralight leading-3">
                <div class="line-through">
                    <span v-if="foreignCar?.max_price > foreignCar?.price">od {{ foreignCar?.max_price ? priceFormat(foreignCar?.max_price) : "?" }} zł</span>
                </div>
                <div>rata/mies.</div>
            </div>
            <div class="flex justify-between items-baseline text-sm font-extralight">
                <div>
                    <span>od </span>
                    <span class="text-2xl font-normal leading-6">{{foreignCar?.price ? priceFormat(foreignCar?.price) : "?" }}</span>
                    <span> zł</span>
                </div>
                <div class="text-azure">
                    <span class="text-2xl font-normal leading-6">{{ foreignCar?.installment ? priceFormat(foreignCar?.installment) : '⏱️'
                        }}</span>
                    <span> zł</span>
                </div>
            </div>
        </div>
        </div>
        <div
            class="w-full h-12 border-azure border border-solid bg-azure px-3 py-4 text-white text-center leading-none font-light cursor-pointer rounded-b-md z-20">
            Zobacz oferty
        </div>
    </NuxtLink>
</template>
